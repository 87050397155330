.products {
  display: flex;
  flex-flow: column;
  gap: var(--grid-gap);
}

.product {
  display: grid;
  gap: var(--grid-gap);
  padding: var(--spacing);

  background-color: var(--products-bg, var(--accent));

  @media (--media-md) {
    grid-template-columns: 16em 1fr 8em;
  }

  .field {
    margin-bottom: 0;
  }
}
