.cards {
  gap: calc(2 * var(--grid-gap));
}

.card {
  display: grid;
  grid-template-rows: 12em 3.5em min-content;
  gap: calc(0.25 * var(--grid-gap));
}

.card__image {
  display: flex;
}

.card__image img {
  margin-top: auto;
  max-width: 100%;
  max-height: 100%;
}

.card__text {
  flex: 1 0 auto;
  min-height: 3em;
}
