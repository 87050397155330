.profgen {
  max-width: 40rem;
  margin: var(--margin) auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  canvas {
    max-width: 100%;
  }

  * + * {
    margin-top: 1em;
  }
}
