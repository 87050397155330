@font-face {
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  src:
    local("Inter-Regular"),
    url(../fonts/Inter/Inter-Regular.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  font-style: italic;
  src:
    local("Inter-italic"),
    url(../fonts/Inter/Inter-italic.var.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  src:
    local("Inter-SemiBold"),
    url(../fonts/Inter/Inter-SemiBold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  font-style: normal;
  src:
    local("Inter-Bold"),
    url(../fonts/Inter/Inter-Bold.woff2) format("woff2");
  font-display: swap;
}

body {
  font-family: Inter, Helvetica, Arial, sans-serif;
}
