.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  fill: var(--icon-color, currentColor);
  display: block;
}

.overflow-arrow .icon {
  width: 3em;
  height: 3em;
}

.button-icon {
  --icon-size: 1em;
  transform: translateX(0.33em);
}
