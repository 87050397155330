.supporting-members {
  --grid-cell: var(--c3);
}

.supporting-members__member {
  border-top: 4px solid var(--accent);
  padding: 8px 0;
}

.committee {
  margin-top: -3.25rem; /*this is the negative overlapping space of the testimonial images*/
}

.committees {
  --_bg: var(--accent);
  --_fg: var(--accent-fg);
  margin-top: var(--spacing);
}

.committees__comittee {
  background: var(--_bg);
  color: var(--_fg);
  padding: var(--spacing);
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0 0 var(--spacing-s) 0;
    font-size: 1.75rem;
  }

  p {
    margin: 0 0 var(--spacing) 0;
  }
}
