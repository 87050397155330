.steps {
  > :nth-child(3n + 1) {
    background-color: var(--blue-dark);
  }

  > :nth-child(3n + 2) {
    background-color: var(--white);
  }

  > :nth-child(3n + 3) {
    background-color: var(--blue);
  }

  > :nth-child(3n + 1),
  > :nth-child(3n + 3) {
    color: var(--white);

    h1,
    h2,
    h3 {
      color: var(--white);
    }
  }
}

.step {
  padding: var(--p);

  > * + * {
    margin-top: 1.5em;
  }
}
