.plugin--donate {
  background-color: var(--donate-bg, var(--accent));
  color: var(--donate-color, var(--fg));
}

.donate-cta {
  max-width: var(--w);

  display: flex;
  gap: var(--spacing);
  flex-flow: column nowrap;
}

.donate-cta__button {
  margin-left: auto;
}

#payrexx-embed {
  width: 100%;
  height: 1400px;
  border: none;
}
