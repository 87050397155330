#edit-tools {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transform: scale(0.7);
  opacity: 0.7;
}
