.form {
  --_gray: #404060;

  font-size: var(--interact-size);

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    transition: opacity 0.1s ease-out;
  }

  fieldset[disabled] {
    opacity: 0.7;
  }

  .field {
    margin-bottom: var(--grid-gap);
  }

  small {
    display: block;
  }

  .field > label {
    display: block;
    margin-bottom: calc(0.25 * var(--grid-gap));
  }

  label.required::after {
    content: "*";
  }

  .field > label.error {
    color: var(--errors-fg);
  }

  label a {
    color: inherit;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  select,
  textarea {
    padding: 0.75rem 0.5rem;
    border-radius: 0;
    border: none;
    &:focus {
      outline: var(--fg) 2px solid;
    }
  }

  input[type="button"] {
    border-radius: 0;
  }

  .widget--radioselect {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .widget--radioselect label {
    margin-left: 0.5rem;
  }

  .widget--selectdatewidget {
    display: flex;
    flex-flow: row wrap;
    label {
      flex: 1 0 100%;
    }
    select {
      flex: 1 1 min-content;
      &:not(:last-of-type) {
        margin-right: var(--spacing-s);
      }
    }
  }

  .widget--hiddeninput {
    display: none;
  }

  .help {
    font-size: 0.8em;
    /* color: var(--_gray); */
    margin-top: calc(0.25 * var(--grid-gap));
  }

  .errors,
  .errorlist {
    color: var(--errors-fg);
  }

  .field-25-25 {
    max-width: 50%;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: var(--grid-gap);
    padding-right: calc(var(--grid-gap) / 2);
  }
  .field-25-50 {
    max-width: 75%;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    gap: var(--grid-gap);
  }
  .field-50-50 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--grid-gap);
  }
  .field-25-75 {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    gap: var(--grid-gap);
  }
  .field-75-25 {
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
    gap: var(--grid-gap);
  }
  .field-25-50-25 {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
    gap: var(--grid-gap);
  }

  @media (--media-xs-forms) {
    .field-25-25,
    .field-25-50,
    .field-50-50,
    .field-25-75,
    .field-75-25 {
      display: block;
    }
  }

  .message {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--_gray);
  }
}

.form--wide-fields {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  select,
  textarea {
    width: 100%;
  }
  input[type="file"] {
    max-width: 100%;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding-right: 1.25rem;

    background-color: var(--bg);
    background-image: url(../static/assets/arrow.svg);
    background-repeat: no-repeat;
    background-position: center right 0.625rem;
    background-size: 1em;
  }
}

@media (--media-md) {
  .form__two-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--grid-gap);

    .widget--checkboxinput {
      grid-column: 1/-1;
    }
  }
}

.engagement-box .formgroup--submit {
  display: block;
}

.engagement-box .form {
  @media (--media-md) {
    .field-50-50 {
      display: block;
    }
    .field-25-75 {
      display: block;
    }
    .field-75-25 {
      display: block;
    }
  }
}

@supports (appearance: none) or (-webkit-appearance: none) or
  (-moz-appearance: none) {
  .form {
    input[type="checkbox"],
    input[type="radio"] {
      --active: var(--fg);
      --active-inner: var(--fg);
      --focus: 2px var(--_gray);
      --border: var(--_gray);
      --border-hover: var(--_gray);
      --background: var(--bg);

      -webkit-appearance: none;
      -moz-appearance: none;

      font-size: 1.5rem;

      height: 1em;
      width: 1em;

      outline: none;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0;
      cursor: pointer;

      background: var(--b, var(--background));

      &:after {
        content: "";
        opacity: 0;
        display: block;
        position: absolute;
        left: -1px;
        top: -1px;
      }

      &:checked {
        &:after {
          opacity: 1;
        }
      }

      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        outline: var(--fg) 2px solid;
      }

      & + label {
        cursor: pointer;
      }
    }

    input[type="checkbox"] {
      border-radius: 0;
      margin-right: var(--spacing-s);

      &:after {
        width: 0.3125em;
        height: 0.5625em;
        border: 0.125em solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 0.35em;
        top: 0.1875em;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }

    input[type="radio"] {
      border-radius: 50%;
      &:after {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background: var(--active-inner);
        transform: scale(0.5);
      }
    }
  }
}
