.pledge-some-links-container {
  display: flex;
  justify-content: space-around;
  .some-links {
    display: flex;
    gap: var(--spacing);
  }
}

.pledge-teaser {
  --_target-color: #6f6767;
  --_target-progress: #a9a9a9;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(calc(var(--c6) - var(--gap)), 100%), 1fr)
  );
  gap: calc(2 * var(--gap));
}

.pledge-teaser__body {
  h1 {
    font-size: 3.25rem;
    margin-bottom: 1.25em;
    word-wrap: break-word; /* Only break words if single words do not fit on a line */
  }
}

.pledge-teaser__counter {
  small {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--_target-color);
  }
  strong {
    font-size: 3rem;
    margin-right: calc(var(--spacing) * 0.5);
  }
  .button {
    margin-block: calc(2 * var(--p)) 0;
  }
}
.pledge-teaser__counter-box {
  width: min(var(--c5), 100%);
  background-color: var(--bg);
  color: var(--fg);
  padding: 3rem;
}
.progress-bar {
  margin-top: var(--spacing-s);
  background-color: var(--_target-progress);
  border-radius: 0.25em;
}
.progress {
  height: 0.5em;
  background-color: var(--accent);
  color: var(--accent-fg);
  margin-block: auto;
  border-radius: 0.25em;
}
.progress.animate {
  animation: progress 1.6s ease-in 1;
}
@keyframes progress {
  from {
    width: 0;
  }
}
