:root {
  --white: #ffffff;
  --black: #000000;

  --blue-light: #99c8f3;
  --blue: #0075e1;
  --blue-dark: #041f64;
  --accent: #7314cc;

  --fg: var(--black);
  --bg: var(--white);

  --accent-fg: var(--black);

  --header-bg: var(--white);

  --link-color: var(--accent);

  --errors-fg: hsl(0, 100%, 27%);

  --menu-bg: var(--black);
  --menu-fg: var(--white);
  --menu-button-bg: var(--accent);
  --menu-button-color: var(--white);

  --button-bg: var(--accent);
  --button-color: var(--white);

  --footer-bg: var(--blue-dark);
  --footer-fg: var(--white);
}

.nav-list-item.active a {
  color: var(--blue);
}

.mobile-menu .language-nav .nav-link {
  color: var(--white);

  &:hover {
    color: var(--_bg);
  }
}

a:hover,
.prose a:not([class]):hover {
  color: var(--blue);
}

h1,
h2,
h3 {
  color: var(--blue);
}
