@media (--media-lg) {
  .articles.auto-grid {
    --columns: 2;
  }
}

.article-box {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
  padding: var(--spacing);
}

.article-box__details {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
}

.arcticle__backlink {
  margin-bottom: 1rem;
}

.article__meta {
  display: flex;
  justify-content: space-between;
  margin: var(--p) 0;
}

.article__meta-info {
  display: flex;
  flex-flow: column;
}

.article__meta-some-links {
  display: flex;
  justify-content: right;
  gap: var(--spacing-s);
}

.article-teaser {
  position: relative;
  display: block;

  figcaption,
  h3,
  small {
    color: inherit;
  }

  > .container {
    display: flex;
    align-items: center;
    gap: var(--spacing);

    flex-wrap: wrap;

    @media (--media-lg) {
      flex-wrap: nowrap;
    }
  }
}

.article-teaser__details {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
  flex: 100 1 auto;

  p {
    max-width: 100%;
  }

  .button {
    margin-left: auto;
  }
}

.article-teaser__image {
  flex: 1 0 auto;

  img {
    aspect-ratio: 320 / 280;
  }
}
