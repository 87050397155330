main {
  flex: 1 1 100%;
  padding-top: 1rem;
}

/*sites with hero*/
#content-anchor + .container h1 {
  margin-top: var(--margin);

  @media (--media-md) {
    margin-top: var(--margin-m);
  }
}

main > *:last-child {
  margin-bottom: var(--margin);

  @media (--media-md) {
    margin-bottom: var(--margin-m);
  }
}

.container,
iframe {
  max-width: var(--w);
  width: 100%;

  padding: 0 var(--p);

  margin-left: auto;
  margin-right: auto;
}

iframe {
  display: block;
  margin-top: var(--margin);
  margin-bottom: var(--margin);
}

.container > .container {
  --p: 0;
}

.container {
  &.maxed {
    padding: 0;
    max-width: var(--w-max);
  }
  &.sm--full {
    padding: 0;
    @media (--media-md) {
      padding: 0 var(--p);
    }
  }
  &.md--full {
    padding: 0;
    @media (--media-lg) {
      padding: 0 var(--p);
    }
  }
}

/* XXX tmp */
.f3cc .f3cc-button.modify {
  right: 20rem !important;
}
