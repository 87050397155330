footer {
  --_bg: var(--footer-bg, var(--fg));
  --_fg: var(--footer-color, var(--bg));

  background: var(--_bg);
  color: var(--_fg);

  --icon-color: var(--_fg);

  padding-top: 3rem;
  padding-bottom: 3rem;

  .logo-container {
    margin-right: initial;
    max-width: 10rem;
  }

  .contact-container {
    --icon-size: 2.5rem;

    display: flex;
    flex-direction: column;
    gap: var(--spacing);
    font-style: normal;
  }

  .contact-link {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
  }

  .some-links-container {
    display: flex;
    gap: 1rem;
  }

  p {
    margin-top: var(--spacing);
  }
}

.footer-container {
  display: flex;
  flex-flow: row wrap;
  gap: 4rem;
}

.footer-nav {
  @media (min-width: 468px) {
    margin-left: auto;
    text-align: right;
  }
}

.footer-nav__list-item {
  &:hover,
  &:focus-visible,
  &.active {
    text-decoration: underline;
  }

  + .footer-nav__list-item {
    margin-top: 0.875em;
  }
}

.footer-nav__link,
.contact-address__link {
  text-decoration: underline;
}
